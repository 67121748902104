  <nb-layout>

    <nb-layout-header *ngIf="page < 3;">
      <nb-layout-column><h4>Connected to your smart meter at <strong>W1T 3JL</strong></h4>
      </nb-layout-column>
      <nb-layout-column><img src="assets/undraw_online_transactions_02ka.svg" />
      </nb-layout-column>
    </nb-layout-header>
  
      <nb-layout-column>

        <img *ngIf="page == 3;" src="assets/dummy_results3.svg" />
        <img *ngIf="page == 4;" src="assets/dummy_results4.svg" />
        <img *ngIf="page == 5;" src="assets/dummy_results5.svg" />

        <nb-card *ngIf="page < 3">
            <nb-card-header>Best matches out of 32 available Smart Tariffs<nb-icon class="pull-right" icon="info"></nb-icon></nb-card-header>
            <br/>
            <img *ngIf="page == 1;" src="assets/dummy_results1.svg" />
            <img *ngIf="page == 2;" src="assets/dummy_results2.svg" />

            <br/>
        </nb-card>
    </nb-layout-column>

    <nb-layout-footer>
        <button nbButton (click)="onPageClick();">Next</button>
    </nb-layout-footer>
</nb-layout>