

    <nb-layout-header>
      <nb-layout-column><h4>Connected to your smart meter at <strong>BN1 6PF</strong></h4>
      </nb-layout-column>
      <nb-layout-column><img src="assets/undraw_online_transactions_02ka.svg" />
      </nb-layout-column>
    </nb-layout-header>

    <div class="row">
        <div class="col">
        <highcharts-chart 
            [Highcharts]="resultsChart"

            [options]="chartOptions"
            [(update)]="updateChartFlag"
            style="width: 100%; height: 400px; display: block;"
            ></highcharts-chart>
        </div>
    </div>
  
    
    <div *ngIf="filterview" class="row">
        <div class="col">

        <nb-card>
            <nb-card-header>Results<nb-icon nbTooltip="This is a tooltip" class="float-right" icon="info"></nb-icon ><nb-toggle (click)="toggleSort($event)" class="float-right"></nb-toggle></nb-card-header>
                <nb-card-body>
                    <div class="row">
                        <div class="col" *ngFor="let result of filterResults; index as i;">
                            
                                <nb-card (click)="graphResults(i)" size="small">
                                    <nb-card-body>
                                        <div class="media-body">
                                            <stsc-badge class="float-right" [value]="result.score"></stsc-badge>
                                            <span class="results_label_top">Tariff</span>
                                            <span class="results_display_name">{{result.display_name}}</span>
            
                                            <span class="results_label">Estimated cost</span>
                                            <span class="results_display">Monthly £ {{ (result.annualcost/12) | number:'1.0-0'}} / Annual £ {{result.annualcost | number:'1.0-0'}}</span>
                                            <span class="results_label">{{result.description}}</span>
                                            <stsc-features isFlex="true" isEV="true" isSolar="true"></stsc-features>
                                        </div>

                                    </nb-card-body>
                                    <nb-card-footer>
                                        <img height="30px" [src]="result.brand_image" />&nbsp;
                                        <stsc-trust [value]="result.brand_score"></stsc-trust>
                                    </nb-card-footer>
                                </nb-card>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <img src="/assets/undraw_mobile_testing_reah.svg" />
                        </div>
                        <div class="col">
                            <div class="results_question_first">How much does <span class="results_blue">Green</span> energy matter to you?</div>
                            <ngx-slider [(value)]="green_value" (valueChange)="greenChange()" [options]="green_options">
                                <ng-template #tooltipTemplate let-tooltip="tooltip" let-placement="placement" let-content="content">
                                    <div class="h-100" tooltipClass="question-class" [ngbTooltip]="tooltip" [placement]="placement">{{content}}</div>
                                </ng-template>
                            </ngx-slider>
                            <div class="results_question">How much does <span class="results_blue">Customer Service</span> matter to you?</div>
                            <ngx-slider [(value)]="service_value" (valueChange)="serviceChange()" [options]="service_options">
                                <ng-template #tooltipTemplate let-tooltip="tooltip" let-placement="placement" let-content="content">
                                    <div class="h-100" tooltipClass="question-class" [ngbTooltip]="tooltip" [placement]="placement">{{content}}</div>
                                </ng-template>
                            </ngx-slider>
                            <div class="results_question">How much does <span class="results_blue">Lowest Price</span> matter to you?</div>
                            <ngx-slider [(value)]="price_value" (valueChange)="priceChange()" [options]="price_options">
                                <ng-template #tooltipTemplate let-tooltip="tooltip" let-placement="placement" let-content="content">
                                    <div class="h-100" tooltipClass="question-class" [ngbTooltip]="tooltip" [placement]="placement">{{content}}</div>
                                </ng-template>
                            </ngx-slider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <button nbButton (click)="toggleFilter(false)" status="primary">View all {{ resultscount }} results</button>
                        </div>
                    </div>
                </nb-card-body>
        </nb-card>
        <nb-accordion multi="true">
            <nb-accordion-item expanded="true">
             <nb-accordion-item-header>Contract</nb-accordion-item-header>
             <nb-accordion-item-body>
                 <div class="row">
                    <div class="col"><span class="text-hint">Tariff Type</span></div>
                    
                    <div class="col">
                        <nb-checkbox (checkedChange)="toggleFeature($event, 3)" status="basic">Fixed</nb-checkbox>&nbsp;
                        <nb-checkbox (checkedChange)="toggleFeature($event, 4)" status="basic">Flexible/Variable</nb-checkbox>&nbsp;
                        <nb-checkbox (checkedChange)="toggleFeature($event, 5)" status="basic">Time of Use</nb-checkbox>&nbsp;
                        <nb-checkbox (checkedChange)="toggleFeature($event, 6)" status="basic">Dynamic</nb-checkbox>&nbsp;
                    </div>
                    
                 </div>
                 <div class="row">
                    <div class="col"><span class="text-hint">Green</span></div>
                    <div class="col"><nb-checkbox (checkedChange)="toggleFeature($event, 2)" status="basic">100% renewable energy</nb-checkbox></div>
                 </div>
                 <div class="row">
                    <div class="col"><span class="text-hint">Contract length</span></div>
                    <div class="col">
                        <nb-checkbox (checkedChange)="toggleFeature($event, 7)" status="basic">None</nb-checkbox>&nbsp;
                        <nb-checkbox (checkedChange)="toggleFeature($event, 8)" status="basic">12 months</nb-checkbox>&nbsp;
                        <nb-checkbox (checkedChange)="toggleFeature($event, 9)" status="basic">24+ months</nb-checkbox>&nbsp;
                    </div>
                 </div>
                 <div class="row">
                    <div class="col"><span class="text-hint">Exit fee</span></div>
                    <div class="col"><nb-checkbox (checkedChange)="toggleFeature($event, 10)" status="basic">No exit fee</nb-checkbox></div>
                 </div>
                 <div class="row">
                    <div class="col"><span class="text-hint">Supplier rating</span></div>
                    <div class="col"><stsc-input-rating></stsc-input-rating></div>
                 </div>
             </nb-accordion-item-body>
            </nb-accordion-item>
            <nb-accordion-item expanded="true">
                <nb-accordion-item-header>Usage</nb-accordion-item-header>
                <nb-accordion-item-body>    
                    <div class="row">
                        <div class="col"><span class="text-hint">Low Carbon Technology</span></div>
                        <div class="col">
                            <nb-checkbox (checkedChange)="toggleFeature($event, 11)" status="basic">Solar</nb-checkbox>&nbsp;
                            <nb-checkbox (checkedChange)="toggleFeature($event, 12)" status="basic">EV</nb-checkbox>&nbsp;
                            <nb-checkbox (checkedChange)="toggleFeature($event, 13)" status="basic">Heat Pump</nb-checkbox>&nbsp;
                        </div>
                     </div>
                </nb-accordion-item-body>
            </nb-accordion-item>
            <nb-accordion-item expanded="true">
                <nb-accordion-item-header>Smart</nb-accordion-item-header>
                <nb-accordion-item-body>
                    <div class="row">
                        <div class="col"><span class="text-hint">Smart meter</span></div>
                        <div class="col">
                            <nb-checkbox (checkedChange)="toggleFeature($event, 14)" status="basic">Smart electric</nb-checkbox>&nbsp;
                            <nb-checkbox (checkedChange)="toggleFeature($event, 15)" status="basic">Smart gas</nb-checkbox>&nbsp;
                            <nb-checkbox (checkedChange)="toggleFeature($event, 16)" status="basic">Smart prepay</nb-checkbox>&nbsp;
                        </div>
                     </div>
                     <div class="row">
                        <div class="col"><span class="text-hint">Traditional meter</span></div>
                        <div class="col">
                            <nb-checkbox (checkedChange)="toggleFeature($event, 17)" status="basic">Traditional electric</nb-checkbox>&nbsp;
                            <nb-checkbox (checkedChange)="toggleFeature($event, 18)" status="basic">Economy 7</nb-checkbox>&nbsp;
                            <nb-checkbox (checkedChange)="toggleFeature($event, 19)" status="basic">Economy 10</nb-checkbox>&nbsp;
                        </div>
                     </div>
                </nb-accordion-item-body>
            </nb-accordion-item>
            <nb-accordion-item expanded="true">
                <nb-accordion-item-header>Price</nb-accordion-item-header>
                <nb-accordion-item-body>
                    <div class="row">
                        <div class="col"><span class="text-hint">Price guarantee for contract</span></div>
                        <div class="col">
                            <nb-checkbox (checkedChange)="toggleFeature($event, 3)" status="basic">Fixed rate</nb-checkbox>&nbsp;
                        </div>
                    </div>
                    <div class="row">
                        <div class="col"><span class="text-hint">Payment types</span></div>
                        <div class="col">
                            <nb-checkbox (checkedChange)="toggleFeature($event, 20)" status="basic">Direct debit</nb-checkbox>&nbsp;
                            <nb-checkbox (checkedChange)="toggleFeature($event, 21)" status="basic">On demand</nb-checkbox>&nbsp;
                            <nb-checkbox (checkedChange)="toggleFeature($event, 22)" status="basic">Prepay</nb-checkbox>&nbsp;
                        </div>
                    </div>
                    <div class="row">
                        <div class="col"><span class="text-hint">Price</span></div>
                        <div class="col">
                            <nb-checkbox (checkedChange)="toggleFeature($event, 23)" status="basic">Less than my current cost</nb-checkbox>&nbsp;
                        </div>
                    </div>
                </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>

        </div>     
    </div>


    <div *ngIf="!filterview" class="row">
        <div class="col">
            <div class="row">
                <div class="col text-center">
                    <button nbButton (click)="toggleFilter(true)" status="primary">Filter best 3</button>
                </div>
            </div>
            <nb-card>
                <nb-card-header>Results<nb-icon nbTooltip="This is a tooltip" class="float-right" icon="info"></nb-icon ><nb-toggle (click)="toggleSort($event)" class="float-right"></nb-toggle></nb-card-header>
                    <nb-card-body>
                        <div class="row">
                            <div class="col">
                                    <ng2-smart-table (rowSelect)="graphFullResults($event)" [settings]="settings" [source]="results"></ng2-smart-table>
                            </div>
                        </div>
                    </nb-card-body>
            </nb-card>
        </div>
    </div>
<!-- <nb-card>
    <nb-card-header>Smart Meter</nb-card-header>
    <nb-card-body>
        <span class="subtitle">Your rate:</span> <span class="h5"> {{ currentTariff.rate }} pence per kWh</span><br>
        <span class="subtitle">Your standing charge:</span> <span class="h5"> {{ currentTariff.standingCharge }} pence per day</span><br>
        <span class="subtitle">Your daily usage: </span> <span class="h5"> {{ dataDaily | number:'1.0-0' }} kWh</span><br>
        <span class="subtitle">Your annual usage: </span> <span class="h5"> {{ annualUsage | number:'1.0-0' }} kWh</span><br>
        <span class="subtitle">Your annual cost: </span> <span class="h5"> {{ (annualCost/100) | currency:'GBP' }}</span><br>
    </nb-card-body>
    
</nb-card> -->