import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { NbStepperModule, NbCardModule, NbLayoutModule, NbRadioModule, NbButtonModule, NbListModule, NbCheckboxModule } from '@nebular/theme';
import { NbIconModule, NbInputModule, NbSelectModule, NbFormFieldModule } from '@nebular/theme';
import { HomeComponent } from './home.component';

import { Routes, RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly
import { CmsService } from 'src/app/cms.service';

import { GlowService } from '../../glow.service';
import { DateFormatService } from '../../date-format.service';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  }
];

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HttpClientModule,
    HttpClientJsonpModule,
    NbStepperModule,
    NbLayoutModule,
    NbCardModule,
    NbRadioModule,
    NbButtonModule,
    NbSelectModule,
    NbListModule,
    NbInputModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule
  ],
  providers: [
    CmsService,
    DateFormatService,
    GlowService
  ]
})
export class HomeModule { }
