import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly

import { NbStepperModule, NbCardModule, NbLayoutModule, NbRadioModule, NbButtonModule, NbListModule, NbCheckboxModule } from '@nebular/theme';
import { NbIconModule, NbInputModule, NbSelectModule, NbFormFieldModule } from '@nebular/theme';

import { CompareComponent } from './compare.component';

import { GlowService } from '../../glow.service';
import { DateFormatService } from '../../date-format.service';


const routes: Routes = [
  {
    path: '',
    component: CompareComponent
  }
];

@NgModule({
  declarations: [ CompareComponent ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NbStepperModule,
    NbLayoutModule,
    NbCardModule,
    NbRadioModule,
    NbButtonModule,
    NbSelectModule,
    NbListModule,
    NbInputModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbIconModule
  ],
  providers: [
    DateFormatService,
    GlowService
  ]
})
export class CompareModule { }
