<!-- 
  <div class="card-row">
    <div class="card-col">
    <h4>No gimmicks. No guessing. <strong>Data-driven Smart Tariffs</strong> in 30 seconds.</h4>
  </div>
  <div class="card-col">
    <img src="assets/undraw_growth_analytics_8btt.svg" />
</div>
</div>


<div class="card-row">
  <div class="card-col">
    <nb-card>
      <nb-card-header>Get started</nb-card-header>
      <nb-card-body><div class="myCF" #myCF></div></nb-card-body>
    </nb-card>
  </div>
</div>


  <div class="card-row">
    <div class="card-col">
      <img src="assets/undraw_personal_settings_kihd.svg" />
    <nb-card accent="info">
      <nb-card-header>Personalised and transparent</nb-card-header>
      <nb-card-body>Choose what is most important to you – Green, Price and Customer Service Rating, or a combination. We are not incentivised by suppliers so make recommendations based only on you</nb-card-body>
    </nb-card>
    </div>
  <div class="card-col">
    <img src="assets/undraw_data_reports_706v.svg" />
    <nb-card accent="info">
      <nb-card-header>Track your Smart Tariff</nb-card-header>
      <nb-card-body>Analyse your real world behaviour against the tariff prediction to better understand where further reductions can be made</nb-card-body>
    </nb-card>
  </div>
  <div class="card-col">
    <img src="assets/undraw_authentication_fsn5.svg" />
    <nb-card accent="info">
      <nb-card-header>Secure and no marketing</nb-card-header>
      <nb-card-body>We take your data seriously, keeping it safe at all times. We don't share it with anyone, ever. You won't receive emails with offers or annoying phone calls</nb-card-body>
    </nb-card>
  </div>
</div>
 -->


  <nb-layout>
    <nb-layout-header>
      <nb-layout-column><h4>No gimmicks. No guessing. <strong>Data-driven Smart Tariffs</strong> in 30 seconds.</h4>
      </nb-layout-column>
      <nb-layout-column><img src="assets/undraw_growth_analytics_8btt.svg" />
      </nb-layout-column>
    </nb-layout-header>

    <nb-layout-column>
      <nb-card>
        <nb-card-header>Get started</nb-card-header>
        <nb-card-body><div class="myCF" #myCF></div></nb-card-body>
      </nb-card>

      <div class="row card-row">
          <nb-layout-column>
            <img src="assets/undraw_personal_settings_kihd.svg" />
            <nb-card accent="info">
              <nb-card-header>Personalised and transparent</nb-card-header>
              <nb-card-body>Choose what is most important to you – Green, Price and Customer Service Rating, or a combination. We are not incentivised by suppliers so make recommendations based only on you</nb-card-body>
            </nb-card>
          </nb-layout-column>

          <nb-layout-column>
            <img src="assets/undraw_data_reports_706v.svg" />
            <nb-card accent="info">
              <nb-card-header>Track your Smart Tariff</nb-card-header>
              <nb-card-body>Analyse your real world behaviour against the tariff prediction to better understand where further reductions can be made</nb-card-body>
            </nb-card>
          </nb-layout-column>

          <nb-layout-column>
            <img src="assets/undraw_authentication_fsn5.svg" />
            <nb-card accent="info">
              <nb-card-header>Secure and no marketing</nb-card-header>
              <nb-card-body>We take your data seriously, keeping it safe at all times. We don't share it with anyone, ever. You won't receive emails with offers or annoying phone calls</nb-card-body>
            </nb-card>
          </nb-layout-column>
      </div>

  </nb-layout-column>
</nb-layout>