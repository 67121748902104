<nb-layout>

  <nb-layout-header style="background-color: #F7F9FC; ">
    <img height="100px" src="assets/logo-dark.svg" />
  </nb-layout-header>


  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer class="dark">
    <nb-layout-column width="375px;">
      <h6 class="text-subtitle-1">Frequently asked questions</h6>
        <ul class="text-list">
          <li><a href="">How long will my switch take?</a></li>
          <li><a href="">Do I need to contact my supplier?</a></li>
          <li><a href="">What happens if I don’t have a smart meter?</a></li>
          <li><a href="">What is a flexible tariff?</a></li>
          <li><a href="">How do I read my SMETS2 meter?</a></li>
        </ul>
    </nb-layout-column>
    <nb-layout-column width="375px;">
      <h6 class="text-subtitle-1">About Smart Tariff</h6>
      <ul class="text-list">
        <li><a href="">Unbiased</a></li>
        <li><a href="">Integration</a></li>
        <li><a href="">No commission paid</a></li>
        <li><a href="">The team</a></li>
      </ul>
    </nb-layout-column>
    <nb-layout-column width="375px;">
      <h6 class="text-subtitle-1">Privacy and data</h6>
      <ul class="text-list">
        <li><a href="">Our Policy</a></li>
        <li><a href="">Right to Access</a></li>
        <li><a href="">Right to Rectification</a></li>
        <li><a href="">Right to Erasure</a></li>
        <li><a href="">Right to Restriction</a></li>
        <li><a href="">Right to Object</a></li>
      </ul>
    </nb-layout-column>

  </nb-layout-footer>
  <nb-layout-footer class="dark">
    <img src="../assets/undraw_wind_turbine_x2k4.svg" />
  </nb-layout-footer>

  <nb-layout-footer>
    <div class="text-caption hint center">
      <ul class="text-nav">
        <li>Cookie Policy</li>
        <li>Terms and Conditions</li>
        <li>Site Map</li>
        <li>About Smart Tariff</li>
        <li>
          <svg style="margin-right: 10px;" width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="social"  fill="#274BDB">
                <g id="video-youtube">
                    <path d="M18,0.5 C19.1,0.5 20,1.4 20,2.5 L20,2.5 L20,18.5 C20,19.6 19.1,20.5 18,20.5 L18,20.5 L2,20.5 C0.9,20.5 0,19.6 0,18.5 L0,18.5 L0,2.5 C0,1.4 0.9,0.5 2,0.5 L2,0.5 Z M10.1474476,5.00051988 L9.875,5 C7.33125,5 4.7875,5.1375 4.375,5.275 C3.275,5.61875 3,8.025 3,10.5 C3,12.975 3.275,15.38125 4.375,15.725 C4.7875,15.8625 7.33125,16 9.875,16 C12.41875,16 14.9625,15.8625 15.375,15.725 C16.475,15.38125 16.75,12.975 16.75,10.5 C16.75,8.025 16.475,5.61875 15.375,5.275 C14.9772321,5.14241071 12.597728,5.00982143 10.1474476,5.00051988 Z M8.5,7.40625 L12.625,10.5 L8.5,13.59375 L8.5,7.40625 Z" id="Combined-Shape"></path>
                </g>
            </g>
          </svg>
          <svg style="margin-right: 10px;" width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="social" fill="#274BDB">
                <g id="post-facebook">
                    <path d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L18,20 C19.1,20 20,19.1 20,18 L20,2 C20,0.9 19.1,0 18,0 L18,0 Z M17,2 L17,5 L15,5 C14.4,5 14,5.4 14,6 L14,8 L17,8 L17,11 L14,11 L14,18 L11,18 L11,11 L9,11 L9,8 L11,8 L11,5.5 C11,3.6 12.6,2 14.5,2 L17,2 L17,2 Z" id="Shape"></path>
                </g>
            </g>
          </svg>
          <svg style="margin-right: 10px;" width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="social" fill="#274BDB">
                <g id="post-twitter">
                    <path d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L18,20 C19.1,20 20,19.1 20,18 L20,2 C20,0.9 19.1,0 18,0 L18,0 Z M15.7,7.3 C15.6,11.9 12.7,15.1 8.3,15.3 C6.5,15.4 5.2,14.8 4,14.1 C5.3,14.3 7,13.8 7.9,13 C6.6,12.9 5.8,12.2 5.4,11.1 C5.8,11.2 6.2,11.1 6.5,11.1 C5.3,10.7 4.5,10 4.4,8.4 C4.7,8.6 5.1,8.7 5.5,8.7 C4.6,8.2 4,6.3 4.7,5.1 C6,6.5 7.6,7.7 10.2,7.9 C9.5,5.1 13.3,3.6 14.8,5.5 C15.5,5.4 16,5.1 16.5,4.9 C16.3,5.6 15.9,6 15.4,6.4 C15.9,6.3 16.4,6.2 16.8,6 C16.7,6.5 16.2,6.9 15.7,7.3 L15.7,7.3 Z" id="Shape"></path>
                </g>
            </g>
          </svg>
        </li>
      </ul>
    </div>
   
     
  </nb-layout-footer>

</nb-layout>
