import { Component, OnInit } from '@angular/core';
import { GlowService } from '../../glow.service';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit {

  dataDaily: number;
  dailyMax: number;
  dailyMin: number;
  annualUsage: number;
  annualCost: number;
  weekDayMax: number;
  weekDayMin: number;
  weekendDayMax: number;
  weekendDayMin: number;
  flexScore: number;
  currentTariff;
  page: number;

  constructor(private glowService: GlowService) { }

  ngOnInit(): void {
    // this.loadData().then(response => console.log('Data loaded')) // .catch(e => console.warn(e))
    this.flexScore = 90;
    this.page = 1;
  }

  onPageClick() {
    this.page = (this.page + 1)%6;
    console.log(this.page);
  }

  async loadData() {
    await this.login()

    // Winter
    // weekday diag 02-07, 02-13, 02-19, 02-25, 03-02
    // weekend 2020-02-01, 2020-02-02, 

    // Summer
    // weekday diag 2020-06-05, 2020-06-11, 2020-06-17, 2020-05-23, 2020-06-29
    // weekend 2020-06-06, 2020-06-07, 2020-06-27, 2020-06-28


    this.dailyMax = 0;
    this.dailyMin = 9999;

    const date = new Date("2020-08-01")
    const dailyElecPattern = await this.glowService.getDailyElecConsumption(date)
    if (dailyElecPattern && Array.isArray(dailyElecPattern["data"])) {
      this.dataDaily = dailyElecPattern["data"].reduce((acc, reading) => {
        if (reading && reading[1]) {
          if(reading[1] > this.dailyMax) { this.dailyMax = reading[1]; }
          if(reading[1] < this.dailyMin) { this.dailyMin = reading[1]; }
          acc += reading[1]
        }
        return acc
      }, 0)
       
    }

    const annualUsage = await this.glowService.getAnnualUsage('energy')
    if (annualUsage && Array.isArray(annualUsage["data"])) {
      this.annualUsage = annualUsage["data"].reduce((acc, reading) => {
        if (reading && reading[1]) {
          acc += reading[1]
        }
        return acc
      }, 0)
    }
    console.log(`Annual Monthly usage ${JSON.stringify(annualUsage)}`)
    
    const annualCost = await this.glowService.getAnnualUsage('cost')
    if (annualCost && Array.isArray(annualCost["data"])) {
      this.annualCost = annualCost["data"].reduce((acc, reading) => {
        if (reading && reading[1]) {
          acc += reading[1]
        }
        return acc
      }, 0)
    }

    console.log(`Annual cost ${JSON.stringify(annualCost)}`)

    this.currentTariff = await this.glowService.getElecTariff()
    console.log(`currentTariff ${JSON.stringify(this.currentTariff)}`)
  }

  async login() {
    const username = "smartenergydemo@glowtest.com"
    const password = "T3stPassw0rd!"
    // const username = "joshuacooper@gmail.com"
    // const password = "Sotnos!15"

    try {

    } catch (e) {
      console.warn(e)
    }
    this.glowService.login({ username, password }).toPromise()
  }

}
