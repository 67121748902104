import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NbRadioGroupComponent } from '@nebular/theme';
import { FormControl } from '@angular/forms';
import { ConversationalForm } from 'conversational-form';
import { HttpHeaders, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('myCF') myCF: ElementRef;
  cf:any;
  initialFormFields = [
    {
      'tag': 'select',
      'name': 'more_info',
      'id' : 'more_info',
      'cf-questions': 'Smart Tariff, Smart Comparison connects with your smart meter data&&The service is compatible with SMETS2 and some older SMETS1 metersYou need to have a In Home Display accessible&&Would you like to learn more about meters?',     
      'cf-input-placeholder': 'Select',
      'isMultiChoice' : false,
      'children' : [
          { 
            'tag': 'option',
            'cf-label': 'No',
            'value': 'No'
          },
          {
            'tag': 'option',
            'cf-label': 'Yes',
            'value' : 'Yes'
          }
      ]
   }

  ];

  article;
  selectedProperty;
  selectedAdults;
  selectedChildren;
  selectedElecBillPeriod;
  selectedGasBillPeriod;
  showSmart = false;
  myMeterType: string;
  meterTypes: string[] = ["Smart", "Traditional"];

  postcodeCheckData = { postcode: "", eui: "" };
  XapplicationID = 'b0f1b774-a586-4f72-9edd-27ead8aa7a8d';

  registrationData = {
                            "user": {
                                "name": "",
                                "username": "",
                                "email": "",
                                "password": "",
                                "directoryId": "951cffa7-863f-4ae7-8f7e-ed682e690f91"
                            },
                            "applicationId": "b0f1b774-a586-4f72-9edd-27ead8aa7a8d",
                            "provisionInfo": {
                                "postcode": "",
                                "eui":""
                            }
                        };

  selectedItemFormControl = new FormControl();

  constructor(private http: HttpClient) {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    console.log('init', this)
    this.cf = ConversationalForm.startTheConversation({
      options: {
        submitCallback: this.submitCallback.bind(this),
        flowStepCallback: this.flowStepCallback.bind(this),
        preventAutoFocus: true,
        robotImage: 'assets/smart_bullet.svg'
      },
      tags: this.initialFormFields,

    });
      this.myCF.nativeElement.appendChild(this.cf.el);
  }

  ngOnChanges(): void {
  }

  // checkPostcode(postcodeCheckData) {
    
  //   var postcodecheckURL = "https://mapserver.glowpro.shop/postcodes/" + postcodeCheckData;

  //   this.http.jsonp(postcodecheckURL, 'callback').subscribe((data : any) =>   {
  //     console.log(data);
  //     this.cf.addRobotChatResponse("Looks like a valid postcode in " + data.result.admin_district);
  //   }, (err) => {
  //     console.log("Error in postcode checking");
  //     error();
  //   });

  // }

  isPasswordStrong(pwd): boolean {
    // var re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/;

    // return re.test(String(pwd));
    return false  ;
  }
  

  flowStepCallback(dto, success, error) {
    console.log("flow step called " + dto.tag.id);
    console.log("value " + dto.tag.value);

    var postcodecheckURL = "https://mapserver.glowpro.shop/postcodes/";

    if(dto.tag.id == 'more_info' || dto.tag.id == 'inputProceed') {
      if(dto.tag.value == 'Yes') {
        this.cf.addTags([
                    {
                        // basic tag
                        "tag": "input",
                        "id": "inputProceed",
                        "value": "Ok to go on?",
                        "type": "text",
                        "cf-input-placeholder": "next",
                        "cf-questions": "Check <a href='moreinfo.html' style='text-decoration:underline'>about smart meters</a>",
                    }
                ]);
        success();
      } else {
        this.cf.addTags([
                    {
                        // basic tag
                        "tag": "input",
                        "id": "inputPostcode",
                        "type": "text",
                        "cf-input-placeholder": "Your postcode",
                        "cf-questions": "Start by entering your postcode",
                        "cf-error": "Doesn't look right"
                    }
                ]);
        success();
      }
    }

    if(dto.tag.id == 'inputPostcode') {
      
      var re = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]*[0-9][A-Z]{2}$/i;
      let tmpPC = String(dto.tag.value).toUpperCase().replace(/\s/g, '');
      console.log(tmpPC);

      if(!re.test(tmpPC)) {
            this.cf.addRobotChatResponse("Postcode should be like W1T 3JL&&Give it another try ...");
            error();
        } else {
      
          postcodecheckURL = postcodecheckURL + tmpPC;

          this.http.jsonp(postcodecheckURL, 'callback').subscribe((data : any) =>   {
            console.log(data);
            if(data.status == 200) {
              this.postcodeCheckData.postcode = tmpPC;
              this.cf.addRobotChatResponse("Thanks, that looks like a valid postcode in " + data.result.admin_district);
              this.cf.addTags([
                  {
                      // basic tag
                      "tag": "select",
                      "id": "example_eui",
                      "type": "select",
                      "cf-input-placeholder": "Select",
                      "cf-questions": "We can access your energy data from a code on your In Home Display&&Depending on your display it will be written as GUID, MAC, or EUI&&It could be a sticker or found in the settings menu&&Would you like to view examples?",
                      "children" : [
                          { 
                            "tag": "option",
                            "cf-label": "No",
                            "value": "No"
                          },
                          {
                            "tag": "option",
                            "cf-label": "Yes",
                            "value": "Yes"
                          }
                      ],
                      "cf-error": "Doesn't look right"
                  }
              ]);
              success(); 
            } else {
              this.cf.addRobotChatResponse("Sorry, doesn't look like you have entered a correct postcode");
              this.cf.addRobotChatResponse("It should be the one on your bill.");
              error();
            }
            
          }, (err) => {
            console.log("Error in postcode checking");
            this.cf.addRobotChatResponse("Sorry, doesn't look like you have entered a correct potcode");
            this.cf.addRobotChatResponse("Check and try again.");
            error();
          });

        }
    
    }

    if(dto.tag.id == 'example_eui') {
      if(dto.tag.value == 'Yes') {
          this.cf.addTags([
                      {
                          // basic tag
                          "tag": "input",
                          "id": "example_eui",
                          "value": "Ok to go on?",
                          "type": "radio",
                          "cf-label": "Glow Display",
                          "cf-image": "assets/glow_eui.jpg",
                          "cf-input-placeholder": "next",
                          "cf-questions": "Here are some examples"
                      }, {
                          "tag": "input",
                          "id": "example_eui",
                          "value": "Ok to go on?",
                          "type": "radio",
                          "cf-label": "Chameleon",
                          "cf-image": "assets/cham_eui.jpg",
                          "cf-input-placeholder": "next"
                      }
                  ]);
          success();
        } else {
          this.cf.addTags([
                      {
                          // basic tag
                          "tag": "input",
                          "id": "inputEUI",
                          "type": "text",
                          "cf-input-placeholder": "Enter In Home Display code",
                          "cf-questions": "Now please enter the code from the In Home Display",
                          "cf-error": "Doesn't look right"
                      }
                  ]);
          success();
        }
      }

    if(dto.tag.id == 'inputEUI') {

        console.log("My EUI " + dto.tag.value);
        var cleaneui = dto.tag.value.trim().toUpperCase().replace(/\s|:|-/g, '');

        var re = /[A-F0-9-]{16}/;

        if(re.test(cleaneui)) {
            console.log("My EUI " + cleaneui);
            if(!re.test(cleaneui)) {
                this.cf.addRobotChatResponse("Make sure to use the number zero&&not O as in Oh my!");
                this.cf.addRobotChatResponse("Give it another try ...");
                error();

            } else {
                this.postcodeCheckData.eui = cleaneui;

                var euicheckURL = "https://apil.glowmarkt.com/api/v0-1/eligibility/checksmetsihd/postcode";

                this.http.post(euicheckURL, JSON.stringify(this.postcodeCheckData), {headers: {
                    'applicationid' : this.XapplicationID,
                    'X-GLOW-Version' : '0',
                    'Content-Type' : 'application/json'
                }}).subscribe((data: any) => {
                      console.log(data);

                      if(data.valid == true && data.status == "OK") {
                            this.registrationData.provisionInfo.postcode = this.postcodeCheckData.postcode;
                            this.registrationData.provisionInfo.eui = this.postcodeCheckData.eui;
                            this.cf.addRobotChatResponse("Looks like you can sign up!");
                            this.cf.addTags([
                                {
                                    // basic tag
                                    "tag": "input",
                                    "id": "myname",
                                    "type": "text",
                                    "cf-questions": "Thanks, that looks good, we’ll fetch your consumption data now&&Whilst in progress we’ll create an account for security&&Please enter your full name",
                                    "cf-input-placeholder": "Your full name",
                                    "cf-error": "No name given"
                                },
                                {
                                    // basic tag
                                    "tag": "input",
                                    "id": "myemail",
                                    "type": "text",
                                    "cf-questions": "Thanks, lastly you email address&&You can create a password later if you want to access your account",
                                    "cf-input-placeholder": "Your email",
                                    "cf-error": "Sorry doesn't look like an email address"
                                },
                                {
                                    // basic tag
                                    "tag": "input",
                                    "id": "mypassword",
                                    "type": "password",
                                    "cf-questions": "To securely access the service, set a password",
                                    "cf-input-placeholder": "Choose a password",
                                    "cf-error": "Sorry, your password is not strong enough."
                                }
                            ]);
                            
                            success();
                        } else {
                            this.cf.addRobotChatResponse("Sorry, doesn't look like you have entered a correct EUI");
                            this.cf.addRobotChatResponse("It should be on your In Home Display.");
                            error();
                        }
                    }, (error) => {
                      this.cf.addRobotChatResponse("Sorry, doesn't look like you have entered a correct EUI");
                      this.cf.addRobotChatResponse("It should be on your In Home Display.");
                      error();
                    });
            }

        } else {
            this.cf.addRobotChatResponse("An EUI should be 16 or 23 characters long&&usually has dashes.");
            this.cf.addRobotChatResponse("Give it another try ...");
            error();
        }

    }

    if(dto.tag.id == "myname") {
        if(dto.tag.value.length > 0) {
            console.log("My name " + dto.tag.value);
            // registrationData.provisionInfo.mpan = mpanCheckData.mpxn;
            this.registrationData.user.name = dto.tag.value;
            success();
        } else {
            this.cf.addRobotChatResponse("Name should not be blank, try again.");
            error();
        }    
    }

    if(dto.tag.id == "myemail") {
        console.log("My email");
        // var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let emailaddress = dto.tag.value.toLowerCase();
        
        if(emailaddress) {  
            console.log("My email " + emailaddress);
            this.registrationData.user.email = emailaddress;
            this.registrationData.user.username = emailaddress;
            success();
        } else {
            this.cf.addRobotChatResponse("Select your email above and edit.");
            error();
        }  
    }

    if(dto.tag.id == "mypassword") {
        console.log("My password");
        console.log("My password " + dto.tag.value);
        if(!this.isPasswordStrong(dto.tag.value)) {
            this.registrationData.user.password = dto.tag.value;
            this.cf.addRobotChatResponse("Thanks, you’re all set");
            this.cf.addRobotChatResponse("There are 32 Smart Tariffs available for you to compare");
            this.cf.addTags([
                      {
                          // basic tag
                          "tag": "input",
                          "id": "example_compare",
                          "value": "octopus",
                          "type": "radio",
                          "cf-label": "Smart rating: 92 <br/>Annual cost: £727",
                          "cf-image": "assets/octopus_stars.png",
                          "cf-input-placeholder": "next",
                          "cf-questions": "Here are some examples"
                      }, {
                          "tag": "input",
                          "id": "example_compare",
                          "value": "bulb",
                          "type": "radio",
                          "cf-label": "Smart rating: 75 <br/>Annual cost: £719",
                          "cf-image": "assets/bulb_stars.png",
                          "cf-input-placeholder": "next"
                      }, {
                          "tag": "input",
                          "id": "example_compare",
                          "value": "sse",
                          "type": "radio",
                          "cf-label": "Smart rating: 69 <br/>Annual cost: £702",
                          "cf-image": "assets/sse_stars.png",
                          "cf-input-placeholder": "next"
                      }
                  ]);
            success();  
        } else {
            this.cf.addRobotChatResponse("You need at least 7 characters&&a capital letter&&and some numbers or symbols");
            error();
        }
    }

    if(dto.tag.id == "example_compare") {
      this.cf.addTags([
        {
            // basic tag
            "tag": "input",
            "id": "end_compare",
            "type": "text",
            "cf-questions": "Or view all 49 matches in a <a href='/results' style='text-decoration:underline'>comparison table</a>",
            "cf-input-placeholder": "Click link for more",
            "cf-error": "No name given"
        }
        ]);
      success();
    }

  }

  submitCallback() {
    var formDataSerialized = this.cf.getFormData(true);
   
    console.log("Formdata, obj:", formDataSerialized);
    this.cf.addRobotChatResponse("You are done. Check the dev console for form data output.")
  }

}
