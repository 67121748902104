import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable, throwError, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DateFormatService } from './date-format.service';

@Injectable()
export class GlowService {

    private applicationId: string = 'b0f1b774-a586-4f72-9edd-27ead8aa7a8d'

    private hostUrl: string = 'https://api.glowmarkt.com/api/v0-1/'
    private tempAccessDirectoryId: string = 'f9f89b6f-db18-4c0a-b82b-cd675bc4c8cf';

    private token: string
    private resources: any[]
    constructor(
        private dateFormatService: DateFormatService,
        private http: HttpClient) {

    }

    //******************** Headers *************/
    private getNoAuthHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'applicationId': this.applicationId });
        return headers
    }

    private getAuthHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'applicationId': this.applicationId,
            'token': localStorage.getItem('id_token') || this.token,
            'X-GLOW-Version': '0'
        });
        return headers
    }

    private getNoAuthOptions() {
        const headers = this.getNoAuthHeaders()
        return { headers: headers }
    }

    private getAuthOptions() {
        const headers = this.getAuthHeaders()
        console.log(headers)
        return { headers: headers }
    }
    // ***************LOGIN*****************
    //accountInfo: {username, password}
    public login({ username, password }): Observable<boolean> {
        this.resources = null
        console.log('Log in');
        const accountInfo = {
            username,
            password,
            ...username.split('@')[1] === 'null.com' && { directoryId: this.tempAccessDirectoryId }
        }

        return this.http.post(this.hostUrl + 'auth/', JSON.stringify(accountInfo), this.getNoAuthOptions())
            .pipe(
                map((res) => this.setToken(res)),
                catchError((error: any) => throwError(error))
            )
    }

    setDefaultOffset() {
        return new Date().getTimezoneOffset();
    }

    // ***************Daily Pattern*****************
    public async getDailyElecConsumption(date: Date) {
        const resource = await this.getElectricityConsumptionResource()
        if (!(resource && resource.resourceId)) {
            throw new Error("no elec consumption resource available")
        }
        const fromDate = this.dateFormatService.apiTimeFromFormat(date)
        const toDate = this.dateFormatService.apiTimeToFormat(date)
        console.log(`Requesting data ${resource.resourceId} ${fromDate}`)
        return this.getResourceReadings(resource.resourceId, fromDate, toDate).toPromise()
    }

    // ***************Annual Consumption/Cost*****************
    public async getAnnualUsage(dimension) {
        let resource;
        if (dimension === 'cost') {
            resource = await this.getElectricityCostResource()
        } else {
            resource = await this.getElectricityConsumptionResource()
        }
        if (!(resource && resource.resourceId)) {
            throw new Error("no elec resource available")
        }
        const { from, to } = this.dateFormatService.get12MonthSlidingWindow()
        console.log(`Requesting data ${resource.resourceId} ${from} ${to}`)
        return this.getResourceReadings(resource.resourceId, from, to, 'P1M').toPromise()
    }


    // ***************Tariff*****************
    public async getElecTariff() {
        const resource = await this.getElectricityConsumptionResource()
        if (!(resource && resource.resourceId)) {
            throw new Error("no elec resource available")
        }
        const tariffResource = await this.getResourceTariff(resource.resourceId).toPromise()
        console.log(tariffResource)
        return this.formatTariff(tariffResource)
    }

    private async getElectricityConsumptionResource() {
        const classifier = 'electricity.consumption'
        return this.getResourceByClassifier(classifier)
    }

    private async getElectricityCostResource() {
        const classifier = 'electricity.consumption.cost'
        return this.getResourceByClassifier(classifier)
    }

    private async getResourceByClassifier(classifierFilter): Promise<any> {
        const resources = await this.getResources().toPromise()
        return resources.find(resource => resource.classifier === classifierFilter)
    }

    private getResources(): Observable<any[]> {
        console.log('Get resources ' + this.applicationId);
        if (Array.isArray(this.resources) && this.resources.length > 0) {
            return of(this.resources)
        } else {
            return this.http.get<any[]>(this.hostUrl + 'resource', this.getAuthOptions())
        }
    }

    getResourceReadings(resourceId: string, fromDate: string, toDate: string, period: string = "PT30M", func: string = 'sum') {
        const offset = this.setDefaultOffset();
        let url = this.hostUrl + 'resource/' + resourceId + '/readings?nulls=1&from=' + fromDate + '&to=' + toDate + '&period=' + period + '&function=' + func;
        if (offset && offset != 0) {
            url += '&offset=' + offset;
        }
        return this.http.get(url, this.getAuthOptions())
    }

    getResourceTariff(resourceId: string) {
        let url = this.hostUrl + 'resource/' + resourceId + '/tariff?';
        return this.http.get(url, this.getAuthOptions())
    }

    private formatTariff(resourceTariff) {
        let tariffInfo = this.setTariffElement(resourceTariff)
        if (resourceTariff.data && resourceTariff.data.length > 0) {
            const [tariffElem] = resourceTariff.data
            if (tariffElem.currentRates) {
                tariffInfo.rate = tariffElem.currentRates.rate;
                tariffInfo.standingCharge = tariffElem.currentRates.standingCharge;
            }
            tariffInfo.type = tariffElem.type;
            tariffInfo.futureRates = tariffElem.futureRates;
        } else {
            tariffInfo = { ...tariffInfo, hasError: true, errorString: 'TARIFF_NOT_FOUND' }
        }
        return tariffInfo
    }

    setTariffElement({ classifier = null, resourceId = null, rate = null, standingCharge = null, futureRates = null, type = null, hasError = false, errorString = null }) {
        return { classifier, resourceId, rate, standingCharge, hasError, errorString, futureRates, type }
    }

    private setToken({ token }: any): boolean {
        if (token) {
            this.token = token
            localStorage.setItem('id_token', token);
            return true
        }
        throw new Error("no token")
    }
}