import { Component, OnInit } from '@angular/core';
import { GlowService } from '../../glow.service';
import { HttpHeaders, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { NbThemeService, NbMediaBreakpoint, NbMediaBreakpointsService } from '@nebular/theme';
import { Options } from '@angular-slider/ngx-slider';
import { StscTrustComponent } from '../../stsc-trust/stsc-trust.component';
import { StscBadgeComponent } from '../../stsc-badge/stsc-badge.component';
import { query } from '@angular/animations';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  breakpoint: NbMediaBreakpoint;
  breakpoints: any;
  themeSubscription: any;

  dataDaily: number;
  dailyMax: number;
  dailyMin: number;
  annualUsage: number;
  annualCost: number;
  weekDayMax: number;
  weekDayMin: number;
  weekendDayMax: number;
  weekendDayMin: number;
  flexScore: number;
  currentTariff;
  results: any;
  resultscount: number;
  filterResults: any;
  selectedResult: any;

  green_value: number;
  green_options: Options;
  price_value: number;
  price_options: Options;
  service_value: number;
  service_options: Options;

  featuremask: number;
  features: Array<boolean>;
  maxfeatures: number;

  sortorder: boolean = false;
  filterview: boolean = true;

  featureQuery = ["smart",
  "green",
  "fixed",
  "variable",
  "tou",
  "dynamic",
  "contract_none",
  "contract_12",
  "contract_24",
  "exit",
  "solar",
  "ev",
  "heatpump",
  "smartelec",
  "smartgas",
  "smartprepay",
  "tradelec",
  "econ7",
  "econ10",
  "dd",
  "demand",
  "prepay"];

  url_query;

  priceOptionLabels = ["All about price", "It's not everything",  "Not concerned",  "Willing to pay more for what I want"];
  serviceOptionLabels = ["Service doesn't matter", "Willing to consider poor service rating", "Decent service rating", "Good service rating", "Service is overiding concern" ];
  greenOptionLabels = [ "Don't care", "Some level of green", "Offsets are OK", "100% green source", "Green ethos too" ];

  monthlyConsumptionData;

  settings = {
    mode: 'external',
    actions: {
      columnTitle: 'Actions',
      add: false,
      edit: false,
      delete: false,
      custom: []
    },
    hideHeader: false,
    hideSubHeader: true,
    pager: {
      display: true,
      page: 1,
      perPage: 50,
    },
    columns: {
      score: {
        title: 'Rating',
        type: 'custom',
        renderComponent: StscBadgeComponent
      },
      supplier: {
        title: 'Supplier',
        type: 'custom',
        renderComponent: StscTrustComponent
      },
      display_name: {
        title: 'Name'
      },
      monthly_cost: {
        title: 'Monthly Cost',
        valuePrepareFunction: (cell, row) => {
          return ( '£ '+  Math.round(row.annualcost/12) );
        }
      },
      annualcost: {
        title: 'Annual Cost',
        valuePrepareFunction: (annualcost) => {
          return ( '£ '+  Math.round(annualcost) );
        }
      }
    }
  };

  resultsChart: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    title: {
      text: 'Cost comparison'
    },
    subtitle: {
        text: 'Source: smart meter'
    },
    series: [{
      type: 'column'
    }, {
      type: 'column'
    },
    {
      type: 'line'
    }
  ],
    legend: { enabled: true},
    xAxis: {
      type: 'datetime'
  },
    yAxis: [{ // Primary yAxis
      labels: {
          format: '£{value}',
          style: {
              color: Highcharts.getOptions().colors[1]
          }
      },
      title: {
          text: 'Cost',
          style: {
              color: Highcharts.getOptions().colors[1]
          }
      }
  }, { // Secondary yAxis
      title: {
          text: 'Energy',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      labels: {
          format: '{value} kWh',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      opposite: true
  }]
  };
  updateChartFlag = false;
  
  constructor(private glowService: GlowService, private http: HttpClient, private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService) { }

  ngOnInit(): void {
    
    this.maxfeatures = 24;
    let features = new Array();

    this.breakpoints = this.breakpointService.getBreakpointsMap();
    this.themeSubscription = this.themeService.onMediaQueryChange()
      .subscribe(([oldValue, newValue]) => {
        this.breakpoint = newValue;
      });

    this.loadResults().then(response => console.log('Tariff data loaded')) // .catch(e => console.warn(e))
    this.loadData().then(response => console.log('Consumption data loaded')) // .catch(e => console.warn(e))
    this.flexScore = 90;

    this.green_value = 3;
    this.service_value = 3;
    this.price_value = 2;
  
    for(var i=0; i < this.maxfeatures; i++) {
      features.push(false);
    }

    this.features = features;

    this.price_options = {
      showTicksValues: false,
      showTicks: true,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ticksTooltip: (v: number): string => {
        return this.priceOptionLabels[v];
      },
      stepsArray: [
        {value: 1, legend: "All about price"},
        {value: 2, legend: "It's not everything"},
        {value: 3, legend: "Not concerned"},
        {value: 4, legend: "Willing to pay more for what I want"},
      ]
    };
    this.service_options = {
      showTicksValues: false,
      showTicks: true,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ticksTooltip: (v: number): string => {
        return this.serviceOptionLabels[v];
      },
      stepsArray: [
        {value: 1, legend: "Service doesn't matter"},
        {value: 2, legend: "Willing to consider poor service rating"},
        {value: 3, legend: "Decent service rating"},
        {value: 4, legend: "Good service rating"},
        {value: 5, legend: "Service is overiding concern"},
      ]
    };
    this.green_options = {
      showTicksValues: false,
      showTicks: true,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ticksTooltip: (v: number): string => {
        return this.greenOptionLabels[v];
      },
      stepsArray: [
        {value: 1, legend: "Don't care"},
        {value: 2, legend: "Some level of green"},
        {value: 3, legend: "Offsets are OK"},
        {value: 4, legend: "100% green source"},
        {value: 5, legend: "Green ethos too"},
      ]
    };

  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }

  greenChange() {
    console.log("Changed green " + this.green_value);
    this.urlQuery();
    this.loadResults();  
  }

  priceChange() {
    console.log("Changed price " + this.price_value);
    this.urlQuery();
    this.loadResults();  
  }

  serviceChange() {
    console.log("Changed service " + this.service_value);
    this.urlQuery();
    this.loadResults();  
  }

  toggleFeature(event, element) {
    this.features[element] = event;
    this.urlQuery();
    this.loadResults();   
  }

  urlQuery() {
    var query = 'priceweight=' + this.price_value + '&greenweight=' + this.green_value + '&serviceweight=' + this.service_value + '&';
    let i = 1;
    if(typeof this.features == 'undefined') {
      return query;
    }

    this.featureQuery.forEach(element => {
      if(this.features[i++]) {
        query += element + "=1&"; 
      }
    });

    if(this.sortorder) query += "sort=score&"

    console.log(query);

    return query;
  }

  toggleSort(event) {
    this.sortorder = this.sortorder ? false : true;
    console.log("change sort " + JSON.stringify(event));
    console.log(this.sortorder);
    this.urlQuery();
    this.loadResults(); 
  }

  toggleFilter(value) {
    this.filterview = this.filterview ? false : true;
  }

  async loadResults() {
        // look at values to make query string

        // var resultsURL = "https://mapserver.glowpro.shop/compare.php?" + this.urlQuery();
        var resultsURL = "/compare_cost.php?" + this.urlQuery();
        console.log(resultsURL);

        await this.http.jsonp(resultsURL, 'callback').subscribe((data : any) =>   {
            console.log(data);
            this.resultscount = data.count;
            this.results = data.results;
            this.filterResults = data.results.slice(0,3);
        
            console.log(data);

            // after the results, then limit to 3 best
    });
  }

  async loadData() {
    await this.login()

    // Winter
    // weekday diag 02-07, 02-13, 02-19, 02-25, 03-02
    // weekend 2020-02-01, 2020-02-02, 

    // Summer
    // weekday diag 2020-06-05, 2020-06-11, 2020-06-17, 2020-05-23, 2020-06-29
    // weekend 2020-06-06, 2020-06-07, 2020-06-27, 2020-06-28


    this.dailyMax = 0;
    this.dailyMin = 9999;

    const date = new Date("2020-08-01")
    const dailyElecPattern = await this.glowService.getDailyElecConsumption(date)
    if (dailyElecPattern && Array.isArray(dailyElecPattern["data"])) {
      this.dataDaily = dailyElecPattern["data"].reduce((acc, reading) => {
        if (reading && reading[1]) {
          if(reading[1] > this.dailyMax) { this.dailyMax = reading[1]; }
          if(reading[1] < this.dailyMin) { this.dailyMin = reading[1]; }
          acc += reading[1]
        }
        return acc
      }, 0)
       
    }

    this.monthlyConsumptionData = JSON.parse(localStorage.getItem('monthlyConsumptionData'));

    if(!this.monthlyConsumptionData || !Array.isArray(this.monthlyConsumptionData)) {
      const annualUsage = await this.glowService.getAnnualUsage('energy')
      if (annualUsage && Array.isArray(annualUsage["data"])) {
        let tsData = [];
        annualUsage["data"].forEach(element => {
          tsData.push([element[0]*1000, element[1]]);
        });
        this.monthlyConsumptionData = tsData;
        localStorage.setItem('monthlyConsumptionData', JSON.stringify(tsData));
      }
    }

      this.chartOptions.series[0]  = {
        name: 'electricity',
        yAxis: 1,
        type: 'column',
        data: this.monthlyConsumptionData
      }
      this.updateChartFlag = true;
      this.annualUsage = this.monthlyConsumptionData.reduce((acc, reading) => {
        if (reading && reading[1]) {
          acc += reading[1]
        }
        return acc
      }, 0)
    
    console.log(`Annual usage ${JSON.stringify(this.annualUsage)}`)
    
    let monthlyCostData = JSON.parse(localStorage.getItem('monthlyCostData'));

    if(!monthlyCostData || !Array.isArray(monthlyCostData)) {
      const annualCost = await this.glowService.getAnnualUsage('cost');
      if (annualCost && Array.isArray(annualCost["data"])) {
        let tsData = [];
        annualCost["data"].forEach(element => {
          tsData.push([element[0]*1000, Math.round(element[1]/100)]);
        });
        console.log("Got server cost data");
        monthlyCostData = tsData;
        localStorage.setItem('monthlyCostData', JSON.stringify(tsData));
      }
    }

      this.chartOptions.series[1] = {
        name: 'current cost',
        yAxis: 0,
        type: 'line',
        data: monthlyCostData
      }
      this.updateChartFlag = true;

      this.annualCost = monthlyCostData.reduce((acc, reading) => {
        if (reading && reading[1]) {
          acc += reading[1]
        }
        return acc
      }, 0)
    

    this.chartOptions.subtitle.text = `Annual cost £ ${JSON.stringify(this.annualCost)}`;

    console.log(`Annual cost ${JSON.stringify(this.annualCost)}`)

    this.currentTariff = await this.glowService.getElecTariff()
    console.log(`currentTariff ${JSON.stringify(this.currentTariff)}`)
  }

  async login() {
    const username = "smartenergydemo@glowtest.com"
    const password = "T3stPassw0rd!"

    try {

    } catch (e) {
      console.warn(e)
    }
    this.glowService.login({ username, password }).toPromise()
  }

  public graphResults(i) {

    console.log('graphResults ' + i);
    let resultData = [];

    if(this.filterResults[i].ratetype == 'flat') {
      this.monthlyConsumptionData.forEach(element => {
          resultData.push([element[0], Math.round((element[1] * this.filterResults[i].unitrate)/100) + (this.filterResults[i].standingcharge*30/100) ]);
      });
    } else {
          resultData = this.filterResults[i].monthlycost;
    }

    this.chartOptions.series[2] = {
      name: this.filterResults[i].display_name,
      yAxis: 0,
      type: 'line',
      data: resultData
    }
    this.updateChartFlag = true;

  }

  public graphFullResults(event) {

    // console.log('graphFullResults ' + JSON.stringify(event.data));

    let resultData = [];

    if(event.data.ratetype == 'flat') {
      this.monthlyConsumptionData.forEach(element => {
          resultData.push([element[0], Math.round((element[1] * event.data.unitrate)/100) + (event.data.standingcharge*30/100) ]);
      });
    } else {
          resultData = event.data.monthlycost;
    }

    this.chartOptions.series[2] = {
      name: event.data.display_name,
      yAxis: 0,
      type: 'line',
      data: resultData
    }
    this.updateChartFlag = true;

    // let resultData = [];

    // if(this.results[i].ratetype == 'flat') {
    //   this.monthlyConsumptionData.forEach(element => {
    //       resultData.push([element[0], Math.round((element[1] * this.results[i].unitrate)/100) + (this.results[i].standingcharge*30/100) ]);
    //   });
    // } else {
    //       resultData = this.results[i].monthlycost;
    // }

    // this.chartOptions.series[2] = {
    //   name: this.results[i].display_name,
    //   yAxis: 0,
    //   type: 'line',
    //   data: resultData
    // }
    // this.updateChartFlag = true;

  }

}
