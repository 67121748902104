import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'stsc-badge',
  templateUrl: './stsc-badge.component.html',
  styleUrls: ['./stsc-badge.component.scss']
})
export class StscBadgeComponent implements OnInit {

  constructor() { }

  @Input() value: number = null;
  @Input() rowData;
  displayValue: number

  ngOnInit(): void {
    if (this.rowData && this.rowData.brand_score) {
      this.displayValue = this.rowData.brand_score;
    } else {
      this.displayValue = this.value;
    }
  }

}
