import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'stsc-input-rating',
  templateUrl: './stsc-input-rating.component.html',
  styleUrls: ['./stsc-input-rating.component.scss']
})
export class StscInputRatingComponent implements OnInit {

  value: number = 4;

  constructor() { }

  ngOnInit(): void {
  }

  onClick(star) {
    console.log(star);
    this.value = star;
  }

}
